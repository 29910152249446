<template>
  <div class="preview" v-if="bg">
    <!-- <h2 class="preview__title">Preview</h2> -->
    <div class="preview__content" :style="style.code"></div>
    <div class="preview__action">
      <button class="btn btn--copy btn--big btn--color" @click="onCopy">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
          />
        </svg>
        Copy CSS code to clipboard
      </button>
    </div>
    <app-notification :show="showNotification" />
  </div>
</template>

<script>
/* eslint-disable no-redeclare */
import AppNotification from "./AppNotification.vue";
export default {
  name: "AppPreview",

  components: {
    AppNotification
  },

  props: {
    bg: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showNotification: false
    };
  },

  computed: {
    style() {
      return this.getCode();
    }
  },

  methods: {
    onCopy(e) {
      e.preventDefault();
      this.showNotification = true;
      const text = this.style.code_text;
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      setTimeout(() => {
        this.showNotification = false;
      }, 1500);
    },

    getCode() {
      if (this.bg.angle > 180) {
        this.bg.angle = this.bg.angle - 180;
      }

      let stripes_size_sum = 0;
      for (var i = 0; i < this.bg.lines.length; i++) {
        stripes_size_sum += this.bg.lines[i].size;
      }

      // GET BACKGROUND SIZE
      let bg_size_x;
      let bg_size_y;
      bg_size_x = stripes_size_sum * 2;

      if (this.bg.angle > 0 && this.bg.angle <= 45) {
        bg_size_x /= 2;
      } else if (this.bg.angle > 45 && this.bg.angle < 90) {
        bg_size_x *= Math.tan((this.bg.angle * Math.PI) / 180) / 2;
      } else if (this.bg.angle > 90 && this.bg.angle <= 135) {
        bg_size_x *= Math.tan(((180 - this.bg.angle) * Math.PI) / 180) / 2;
      } else if (this.bg.angle > 135 && this.bg.angle < 180) {
        bg_size_x /= 2;
      }

      // if (
      //   self.ratio &&
      //   !(props.angle == 0 || props.angle == 90 || props.angle == 180)
      // ) {
      //   bg_size_x = bg_size_x / Math.sin((props.angle * Math.PI) / 180);
      // }

      bg_size_y = bg_size_x;
      if (this.bg.angle > 90 && this.bg.angle < 180) {
        bg_size_y =
          bg_size_x * Math.tan(((180 - this.bg.angle) * Math.PI) / 180);
      } else if (
        this.bg.angle != 90 &&
        this.bg.angle != 0 &&
        this.bg.angle != 180
      ) {
        bg_size_y = bg_size_x * Math.tan((this.bg.angle * Math.PI) / 180);
      }

      if (bg_size_y > bg_size_x) {
        let props = bg_size_x;
        bg_size_x = (bg_size_x * bg_size_x) / bg_size_y;
        bg_size_x = bg_size_x.toFixed(2);
        bg_size_y = props;
      }

      bg_size_x = parseFloat(bg_size_x).toFixed(2);
      bg_size_y = parseFloat(bg_size_y).toFixed(2);

      // GET PERCENT CODE

      var percents = [];
      for (var i = 0; i < this.bg.lines.length; i++) {
        percents[i] = (this.bg.lines[i].size * 100) / stripes_size_sum / 2;
      }

      let percents_code = "";
      let percents_code_text = "";
      for (var i = 0; i < this.bg.lines.length * 2; i++) {
        var percents_sum = 0;
        for (var j = 0; j <= i; j++) {
          var k = j;
          if (j >= this.bg.lines.length) {
            k = j - this.bg.lines.length;
          }
          percents_sum += percents[k];
        }
        percents_sum = parseFloat(percents_sum)
          .toFixed(2)
          .replace(/\.0+$/, "");

        var j = i;
        if (j >= this.bg.lines.length) {
          j = j - this.bg.lines.length;
        }

        var k = j + 1;
        if (k >= this.bg.lines.length) {
          k = k - this.bg.lines.length;
        }

        var color_name = this.bg.lines[j].color;
        if (this.bg.lines[j].color_name) {
          color_name = this.bg.lines[j].color_name;
        }

        var color_name2 = this.bg.lines[k].color;
        if (this.bg.lines[k].color_name) {
          color_name2 = this.bg.lines[k].color_name;
        }

        if (i == this.bg.lines.length * 2 - 1) {
          percents_code += this.bg.lines[j].color + " " + percents_sum + "%";
          percents_code_text += color_name + " " + percents_sum + "%";
        } else {
          percents_code +=
            this.bg.lines[j].color +
            " " +
            percents_sum +
            "%, " +
            this.bg.lines[k].color +
            " " +
            percents_sum +
            "%, ";
          percents_code_text +=
            color_name +
            " " +
            percents_sum +
            "%, " +
            color_name2 +
            " " +
            percents_sum +
            "%, ";
        }
      }

      return {
        code: {
          backgroundImage: `linear-gradient(${this.bg.angle}deg, ${percents_code})`,
          backgroundSize: `${bg_size_x}px ${bg_size_y}px`
        },
        code_text: `background-image: linear-gradient(${this.bg.angle}deg, ${percents_code_text});
        background-size: ${bg_size_x}px ${bg_size_y}px;`
      };
    }
  }
};
</script>

<style scoped>
.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview__title {
  /* align-self: flex-start; */
  padding: 0.5em 1em 0.5em 0;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.preview__content {
  width: 100%;
  min-height: 200px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  resize: vertical;
  overflow: auto;
}

.preview__action {
  margin-top: 2em;
}

/* .preview .btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
} */
</style>
