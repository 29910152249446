<template>
  <transition name="fade">
    <div class="notification" v-show="show">
      <transition name="zoom">
        <div class="notification__content" v-show="show">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            />
          </svg>
          <h1>CSS Copied to clipboard</h1>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AppNotification",

  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2em;
}

.notification__content {
  width: 50%;
  max-width: 400px;
  padding: 2em;
  text-align: center;
  border-radius: 16px;
  background-color: white;
}

.notification__content svg {
  width: 200px;
  stroke: rgb(103, 199, 7);
}

@keyframes fade-enter {
  from {
    opacity: 0;
  }
}

.fade-enter-active {
  animation: fade-enter 310ms both ease-in;
}

@keyframes fade-leave {
  to {
    opacity: 0;
  }
}

.fade-leave-active {
  animation: fade-leave 310ms both ease-out;
}

@keyframes zoom-enter {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.zoom-enter-active {
  animation: zoom-enter 310ms both cubic-bezier(0.4, 0, 0, 1.5);
}

@keyframes zoom-leave {
  to {
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.zoom-leave-active {
  animation: zoom-leave 310ms both;
}
</style>
