<template>
  <div class="form">
    <section class="form__block form__block--m">
      <h2 class="form__title">Angle</h2>
      <h4 class="form__subtitle">choose popular</h4>
      <div class="form__row form__row--flex">
        <div class="form__item">
          <input
            type="radio"
            id="angle"
            name="angle"
            value="45"
            v-model="state.angle"
          />
          <label for="angle"></label>
        </div>
        <div class="form__item">
          <input
            type="radio"
            id="angle1"
            name="angle"
            value="90"
            v-model="state.angle"
          />
          <label class="label--90" for="angle1"></label>
        </div>
        <div class="form__item">
          <input
            type="radio"
            id="angle2"
            name="angle"
            value="135"
            v-model="state.angle"
          />
          <label class="label--135" for="angle2"></label>
        </div>
      </div>
      <div class="form__row">
        <h4 class="form__subtitle">or enter your own</h4>
        <input type="text" v-model.number="state.angle" />
      </div>
    </section>
    <section class="form__block form__block--l">
      <h2 class="form__title">Lines</h2>
      <button class="btn add-line" @click="onAdd">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Add line
      </button>
      <div class="form__row" v-for="(line, index) in state.lines" :key="index">
        <h4 class="form__subtitle">Line {{ index + 1 }}</h4>
        <div class="form__row--flex">
          <div class="form__item">
            <label class="text-label" :for="`size${index}`">Width</label>
            <input
              type="text"
              :id="`size${index}`"
              v-model.number="line.size"
            />
          </div>
          <div class="form__item">
            <label class="text-label" :for="`color${index + 100}`">Color</label>
            <input
              type="text"
              :id="`color${index + 100}`"
              v-model="line.color"
            />
          </div>
          <div class="form__item">
            <input type="color" :id="`color${index}`" v-model="line.color" />
            <label :for="`color${index}`" class="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                />
              </svg>
              Choose color
            </label>
          </div>
          <div class="line-action" v-if="index > 1" @click="onRemove(line)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AppForm",

  data() {
    return {
      state: {
        angle: "45",
        lines: [
          {
            size: 20,
            color: "#ffffff"
          },
          {
            size: 20,
            color: "#000000"
          }
        ]
      }
    };
  },

  watch: {
    state: {
      handler(newVal) {
        this.$emit("update", newVal);
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onAdd() {
      this.state.lines.push({
        size: 20,
        color: "#ffffff"
      });
    },

    onRemove(line) {
      this.state.lines = this.state.lines.filter(l => l !== line);
    }
  }
};
</script>

<style scoped>
.form {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.form__row + .form__row {
  margin-top: 20px;
}

.form__row--flex {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.form__row--flex .form__item {
  flex-grow: 1;
}

.form__title {
  font-size: 1.3rem;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.form__subtitle {
  font-size: 1rem;
  margin-bottom: 10px;
}

.form__block {
  position: relative;
  padding: 1em 1.5em;
  min-height: 100px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}

.form__block--m {
  width: 30%;
}

.form__block--l {
  width: 70%;
}

.text-label {
  display: block;
  margin-bottom: 4px;
}

input {
  appearance: none;
  border-radius: 6px;
  border: 1px solid rgb(209, 213, 219);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 70px;
  aspect-ratio: 1;
  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    rgba(0, 0, 0, 0.8) 25%,
    rgba(0, 0, 0, 0.8) 50%,
    #ffffff 50%,
    #ffffff 75%,
    rgba(0, 0, 0, 0.8) 75%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-size: 10px 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

input[type="radio"] + label.label--90 {
  background-image: linear-gradient(
    90deg,
    #ffffff 25%,
    rgba(0, 0, 0, 0.8) 25%,
    rgba(0, 0, 0, 0.8) 50%,
    #ffffff 50%,
    #ffffff 75%,
    rgba(0, 0, 0, 0.8) 75%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-size: 10px 10px;
}

input[type="radio"] + label.label--135 {
  background-image: linear-gradient(
    135deg,
    #ffffff 25%,
    rgba(0, 0, 0, 0.8) 25%,
    rgba(0, 0, 0, 0.8) 50%,
    #ffffff 50%,
    #ffffff 75%,
    rgba(0, 0, 0, 0.8) 75%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-size: 10px 10px;
}

input[type="radio"]:checked + label {
  color: white;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.8) 0px 2px 4px 0px inset;
  animation: stripeBackgroundPosition 2s linear;
}

input[type="color"] {
  opacity: 0;
}

.line-action {
  width: 36px;
  cursor: pointer;
}
.add-line {
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes stripeBackgroundPosition {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -100px 0;
  }
}

@media screen and (max-width: 1100px) {
  .form {
    flex-direction: column;
  }

  .form__block--m,
  .form__block--l {
    width: 100%;
  }

  .form__row--flex .form__item {
    flex-grow: 0;
  }
}

@media screen and (max-width: 756px) {
  .form__row--flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .form__row--flex .form__item {
    width: 100%;
  }

  input {
    width: 100%;
  }
}
</style>
